import {useState, React} from 'react'
import { CiMenuBurger } from "react-icons/ci";
import { logout, joinGroup } from '../store/actions/auth';
import { useDispatch } from 'react-redux';

const PanelMenu = ({navigation, callback}) => {
    const [ showMenu, setShowMenu ] = useState(false);

    const dispatch = useDispatch();
    const handleLogoutClick = () => {
        dispatch(logout());
        dispatch(joinGroup(0));
    }
 
    return (
    <div class="md:hidden flex flex-col items-center space-x-0 relative">
        <div class="w-[120px] flex items-center justify-end"><CiMenuBurger onClick={()=>setShowMenu(!showMenu)} /></div>
        {showMenu && (
            <div className='absolute top-5 w-full z-40 right-0 flex flex-col bg-gray-100' onMouseLeave={()=>setShowMenu(false)}>
                {navigation.map((item, index) => (
                    <div key={index} class="border border-b-gray-300 font-light px-4 py-2 text-xs text-gray-800 cursor-pointer hover:bg-orange-400">
                    <a onClick={()=>callback(index)}>
                    {item}
                    </a>
                    </div> 
                ))}   
                <div onClick={()=>handleLogoutClick()}  class="border border-b-gray-300 font-medium  px-4 py-2 text-xs text-gray-800 cursor-pointer hover:bg-orange-400">
                    Logout
                </div>
            </div>
        )}
    </div>
    )
}

export default PanelMenu