import { useEffect, useState } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { adjustColor } from '../../utils';
const baseMarkerColor = '#7F7FFF';

const Direction = ({data, index, size, optimize, callback}) => {
    const map = useMap();
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionService] = useState(null);
    const [directionsRenderer, setDirectionRenderer] = useState(null);
    const [route, setRoute] = useState();

    useEffect(()=>{
        setRoute(data);
    }, [data])
    useEffect(()=>{
        if (!routesLibrary || !map) return;
        let color = adjustColor(baseMarkerColor, 
            96*(Math.trunc(index/3)+1)*(index % 3 !== 2 ? 1 : -1),
               index % 3);
        setDirectionService(new routesLibrary.DirectionsService());
        setDirectionRenderer(new routesLibrary.DirectionsRenderer({map, 
            hideRouteList:true, 
            //suppressMarkers: true,
            polylineOptions: {
            strokeColor: color,
          }}));
    }, [map, routesLibrary]);

    useEffect(()=>{
        if (!directionsService || !directionsRenderer) return;
        const len = route.intermediate_addresses.length;
        const waypts = [];      
        var start = {};
        var end = {};
        var start_id = -1;
        var end_id = -1;
        var waypt_ori = [];
        for (let i = 0; i < len; i++) {
            if (route.intermediate_addresses[i].id ===  route.start_addr) {
                start.lat = parseFloat(route.intermediate_addresses[i].lat);
                start.lng = parseFloat(route.intermediate_addresses[i].lng);
                start_id = i;
                continue;
            }
            if (route.intermediate_addresses[i].id ===  route.end_addr) {
                end.lat = parseFloat(route.intermediate_addresses[i].lat);
                end.lng = parseFloat(route.intermediate_addresses[i].lng);
                end_id = i;
                continue;
            }
            waypt_ori.push(i);
            waypts.push({
              location: {lat: parseFloat(route.intermediate_addresses[i].lat), lng: parseFloat(route.intermediate_addresses[i].lng)},
              stopover: true,
            });
        }
        //console.log(optimize?"+":"-"+":"+start_id+"-"+end_id);
        //console.log(waypt_ori.map(i => route.intermediate_addresses[i]));
        directionsService.route({
            origin: start,
            destination: end,
            provideRouteAlternatives: false,
            travelMode: 'DRIVING',
            waypoints: waypts,
            optimizeWaypoints: optimize,
       }).then(response => {
            directionsRenderer.setDirections(response);
            // add marker with number
            /*
            var my_route = response.routes[0];
            var i=0;
            for (; i<my_route.legs.length; i++) {
                console.log(my_route.legs[i].start_location);
                var marker = new window.google.maps.Marker({
                    position: my_route.legs[i].start_location,
                    label: ""+(i+1),
                    map: map
                });
            }
            var marker = new window.google.maps.Marker({
                position: my_route.legs[i-1].end_location,
                label: ""+(i+1),
                map: map
            });
            */
            if (optimize) {
                let order = [];
                order.push(start_id);
                for (var i=0; i<response.routes[0].waypoint_order.length; i++) {
                    order.push(waypt_ori[response.routes[0].waypoint_order[i]]);
                }
                order.push(end_id);
                callback(order, route);
            }
       }).catch(err => {
            console.log(err);
       });
    }, [directionsService, directionsRenderer, route]);
}

export default Direction

