import React, { useEffect, useState } from 'react'
import Chart from '../Chart';
import authAxios from '../../utils';
import { getActiveRoutesUrl, getPackageDataUrl, getDriverListUrl } from '../../constants';
import RecordTable from '../RecordTable';
import { Divider } from '@mui/material';
  
const packageColumns = [
    {
        id: 'created_at',
        sort: true,
        disablePadding: false,
        label: 'Created at',
    },
    {
        id: 'tag',
        sort: true,
        disablePadding: false,
        label: 'Tag',
    },
    {
        id: 'status',
        sort: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'customer',
        sort: true,
        disablePadding: false,
        label: 'Customer',
    },
    {
        id: 'address',
        sort: true,
        disablePadding: false,
        label: 'Address',
    },
    {
        id: 'business',
        sort: true,
        disablePadding: false,
        label: 'Business',
    },
];  


const routeColumns = [
    {
        id: 'created_at',
        sort: true,
        disablePadding: false,
        label: 'Created at',
    },
    {
        id: 'status',
        sort: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'driver',
        sort: true,
        disablePadding: false,
        label: 'Driver',
    },
    {
        id: 'profit',
        sort: true,
        disablePadding: false,
        label: 'Profit',
    },
    {
        id: 'addreses',
        sort: false,
        disablePadding: false,
        label: 'Addresses',
    },
]

const driverColumns = [
    {
      id: 'email',
      sort: true,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'first_name',
      sort: true,
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'last_name',
      sort: true,
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'phone_number',
      sort: true,
      disablePadding: false,
      label: 'Phone Number',
    },
    {
      id: 'created_at',
      sort: true,
      disablePadding: false,
      label: 'Created at',
    },
    {
        id: 'verified',
        sort: true,
        disablePadding: false,
        label: 'Verified',
    },   
  ];

const Statis = () => {
    const [category, setCategory] = useState(0);
    const [routes, setRoutes] = useState([]);
    const [showTable, setShowTable] = useState(true);
    const [packages, setPackages] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [duration, setDuration] = useState(7);

    useEffect(()=>{
        getRouteData(duration);
    }, []);

    const getRouteData = days => {
        authAxios.get(getActiveRoutesUrl, {params: {days : days}})
        .then(response => {
            let routes = [];
            for (let i=0; i<response.data.length; i++) {
                if (response.data[i].status === 'DC') {
                    continue;
                }
                let date = new Date(response.data[i].created_at).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    });
                let row = {
                    created_at: date,
                    status: response.data[i].status_display,
                    driver: response.data[i].driver_name,
                    profit: response.data[i].profit.toString(),
                    addresses: response.data[i].intermediate_addresses,
                }
                /*let row = {
                    id: response.data[i].id,
                    created_at: response.data[i].created_at,
                    status: response.data[i].status_display,
                    driver_name: response.data[i].driver_name,
                    profit: '$'+response.data[i].profit,
                    start_addr : response.data[i].start_addr
                }*/
                routes.push(row);
            }
            setRoutes(routes);
        })
        .catch(err=>console.log(err));
    };

    const getPackageData = (days) => {
        authAxios.get(getPackageDataUrl, {params : {days: days}})
        .then(response => {
            let packages = [];
            for (let i=0; i<response.data.length; i++) {
                let date = new Date(response.data[i].created_at).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    });
                let updated_date = new Date(response.data[i].updated_at).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    });
                let row = {
                    tag: response.data[i].unique_id,
                    created_at: date,
                    updated_at: updated_date,
                    status: response.data[i].status_display,
                    memo: response.data[i].memo,
                    photo: response.data[i].delivered_photo,
                    customer: response.data[i].customer_name,
                    customer_phone: response.data[i].customer_phone,
                    address : response.data[i].address,
                    business: response.data[i].business,
                    qrcode: response.data[i].qrcode,
                }
                packages.push(row);
            }
            setPackages(packages);
        })
        .catch(err=>console.log(err));       
    };

    const getDriverData = () => {
        authAxios
        .get(getDriverListUrl)
        .then((response => {
            let d = [];
            for (let i=0; i<response.data.length; i++) {
                let date = new Date(response.data[i].profile.created_at).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    });
                let row = {
                    email: response.data[i].email,
                    first_name: response.data[i].first_name,
                    last_name: response.data[i].last_name,
                    phone_number: response.data[i].profile.phone_number,
                    created_at: date,
                    verified: response.data[i].verified,
                }
                d.push(row);
            }          
            setDrivers(d);
        }))
        .catch(err => {
          console.log(err);
        });     
    };

    const switchTable = index => {
        setCategory(index);
        switch (index) {
            case 0:
                getRouteData(duration);
            break;
            case 1: 
                getPackageData(duration);
            break;
            case 2: 
                getDriverData();
            break;
            case 3: 

            break;
            default:
            break;
        }
    };

    const onDurationChange = days => {
        setDuration(days);
        if (category === 0) {
            getRouteData(days);
        }
        if (category === 1) {
            getPackageData(days);
        }
    };

  return (
    <div class="flex flex-col-reverse lg:flex-row gap-2 mt-4 mb-8 w-full ">
        <div class="lg:w-1/4 w-full  flex flex-col gap-2 flex-shrink-0 flex-grow-0 pr-2 border-r border-gray-200">
            <button class="default_btn mb-2 w-full" type="submit" onClick={()=>switchTable(0)}>Route</button>   
            <button class="default_btn my-2 w-full" type="submit" onClick={()=>switchTable(1)}>Package</button>   
            <div class="flex items-center my-2 justify-center gap-2">
                <input type="radio" checked={duration===7} onChange={()=>onDurationChange(7)} name="week-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" /> 
                <label for="week-radio" class="default_text">Week</label>
                <input type="radio" checked={duration===30} onChange={()=>onDurationChange(30)} name="month-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
                <label for="month-radio" class="default_text">Month</label>
                <input type="radio" checked={duration===0} onChange={()=>onDurationChange(0)} name="all-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
                <label for="all-radio" class="default_text">All</label>
            </div>
            <Divider />
            <button class="default_btn my-2 w-full" type="submit" onClick={()=>switchTable(2)}>Driver</button>   
            <button class="default_btn my-2 w-full" type="submit" onClick={()=>switchTable(3)}>Business</button>   
            <div class="flex items-center my-2 justify-center gap-2">
                <input type="radio" checked={showTable} onChange={()=>setShowTable(true)} name="table-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" /> 
                <label for="default-radio-1" class="default_text">Statis</label>
                <input type="radio" checked={!showTable} onChange={()=>setShowTable(false)} name="chart-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
                <label for="default-radio-2" class="default_text">Chart</label>
            </div>
        </div>
        <div className='w-full overflow-y-auto flex-grow'>
            {showTable ? (
                <>
                {category === 0 && <RecordTable table="route" rows={routes} columns={routeColumns}/>}
                {category === 1 && <RecordTable table="package" rows={packages} columns={packageColumns} />}
                {category === 2 && <RecordTable table="driver" rows={drivers} columns={driverColumns}/>}
                </>
            ) : (
                <Chart />
            )}

        </div>
    </div>  
    )
}

export default Statis