import axios from "axios";
import { localhost, refreshTokenUrl } from "./constants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";


export const authAxios = axios.create({
  baseURL: localhost,
  /*
  headers: {
    Authorization: `Token ${localStorage.getItem("token")}`
  }*/
});


export default authAxios;

export const adjustColor = (color, amount, component) => {
    // Remove the '#' if present
    color = color.replace(/^#/, '');
    
    // Split the color into its R, G, B components
    let r = parseInt(color.substring(0, 2), 16);
    let g = parseInt(color.substring(2, 4), 16);
    let b = parseInt(color.substring(4, 6), 16);
    let R = color.substring(0, 2);
    let G = color.substring(2, 4);
    let B = color.substring(4, 6);
    // Adjust the specified component
    switch(component) {
        case 0:
            R = Math.min(255, Math.max(0, r + amount)).toString(16).padStart(2, '0');
            break;
        case 1:
            G = Math.min(255, Math.max(0, g + amount)).toString(16).padStart(2, '0');
            break;
        case 2:      
            B = Math.min(255, Math.max(0, b + amount)).toString(16).padStart(2, '0');
            break;
        default:
            throw new Error("Invalid component. Use 'R', 'G', or 'B'.");
    }
    
    // Return the adjusted color
    return `#${R}${G}${B}`;
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const phoneRegex = /^\d{10}$/;
