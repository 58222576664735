import React from 'react'
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { joinGroup } from '../store/actions/auth';
import { useSelector, useDispatch } from 'react-redux';
import Logout from './Logout';
import { user_group } from '../constants';

const Header = () => {
  const [showCategorySelection, setShowCategorySelection] = useState(false);
  const group = useSelector((state) => state.auth.group);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLoginClick = (group) => {
    dispatch(joinGroup(group))
    navigate("/login")
  }

  const token = useSelector((state) => state.auth.token);
  const gotoUserPanel = (group) => {
    if (group === user_group.Driver) {
      navigate("driver-panel");
    } else if (group === user_group.Business) {
      navigate("business-panel");
    } else {
      navigate("broker-panel");
    }
  }
  return (
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 border-b border-gray-200">
        <div class="flex h-16 items-center justify-between">
            <img src="logo.png" alt="" class="w-[150px] md:w-[200px] object-cover"/>
            {token && token !== null && token !== undefined ? (
              <div class="flex justify-end items-center gap-4">
                {group !== user_group.Guest && (
                  <button onClick={()=>gotoUserPanel(group)} type="button" class="default_btn w-[150px]">
                      <div>Dashboard</div>
                  </button> 
                )}
                <Logout />
              </div>
            ) : (
              <div class="flex flex-col items-center space-x-0 relative">
                  <button type="button" onClick={()=>setShowCategorySelection(!showCategorySelection)} class="default_btn w-[150px]">
                      <div>Login/Signup</div>
                  </button>
                  {showCategorySelection && (
                    <div className='absolute top-12 z-40 left-0 flex flex-col w-full  bg-gray-100' onMouseLeave={()=>setShowCategorySelection(false)}>
                      <div onClick={()=>handleLoginClick(user_group.Driver)} class="inline-flex items-center border border-b-gray-300 font-medium justify-center px-4 py-2 text-sm text-gray-900 cursor-pointer hover:bg-orange-400">
                          Driver
                      </div>     
                      <div onClick={()=>handleLoginClick(user_group.Business)} class="inline-flex items-center border border-b-gray-300 font-medium justify-center px-4 py-2 text-sm text-gray-900 cursor-pointer hover:bg-orange-400">
                          Business
                      </div>                                     
                    </div>
                  )}
              </div>
            )}
        </div>
      </div>
  )
}

export default Header