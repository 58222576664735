import { React, useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <div>
      <Header />
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 border-b border-gray-200">
        <div class="grid grid-rows-2 gap-4 lg:gap-16 lg:grid-rows-1 grid-cols-1 lg:grid-cols-2 py-4">
          <div class="h-120 overflow-hidden text-lg text-gray-700 row-start-2 lg:row-start-1">
          AuspDelivery is a premier local delivery company dedicated to bridging the gap between local stores and their customers with unparalleled speed and efficiency. Specializing in the swift and reliable transport of goods, we ensure that your purchases arrive at your doorstep in record time. Whether you're ordering groceries, essential supplies, or specialty items from your favorite local stores, AuspDelivery guarantees a seamless and prompt delivery experience. Our commitment to excellence, coupled with cutting-edge technology and a team of professional drivers, makes us the trusted choice for all your local delivery needs. Choose AuspDelivery for speed, reliability, and exceptional service.
          </div>
          <img class="h-120 overflow-hidden object-fill row-start-1"src="hero.png" alt=""></img>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home