import { React, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logout from '../components/Logout';
import { useSelector } from 'react-redux';
import { user_group } from '../constants';
import AddressMap from '../components/broker/AddressMap';
import Statis from '../components/broker/Statis';
import RouteControl from '../components/broker/RouteControl';
import Profile from '../components/Profile';

import PanelMenu from '../components/PanelMenu';

const navigation = [
    'Addresses', 'Routes', 'Statis', 'Profile'
]

const BrokerPanel = () => {
    const [ activeNav, setActiveNav ] = useState(0);
    const group =  useSelector((state) => state.auth.group);
    const navigate = useNavigate();

    if (group !== user_group.Broker) {
      navigate("/");
    }

    return (
      <div class="mx-auto max-w-7xl h-screen px-4 sm:px-6 lg:px-8 flex flex-col justify-start">
          <div class="w-full border-b  border-gray-200">
              <div class="flex h-16 items-center justify-between">
                <div class="flex items-center">
                  <Link  to="/"  class="flex-shrink-0">
                    <img
                      alt="Ausp Delivery"
                      src="logo.png"
                      class="w-[150px] md:w-[200px] object-cover"
                    />
                  </Link>
                  <div class="hidden md:block">
                    <div class="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item, index) => (
                        <a
                          onClick={()=>setActiveNav(index)}
                          key={index}
                          class={
                            index === activeNav ? ' border-orange-400 border-2 text-gray-700 rounded-md px-3 py-2 text-sm font-medium' 
                                          : ' hover:border-orange-400 hover:border-2 text-gray-700 rounded-md px-3 py-2 text-sm font-medium'
                          }
                        >
                          {item}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <PanelMenu navigation={navigation} callback={setActiveNav}/>
                <div class="hidden md:block">
                    <Logout />
                </div>
              </div>
          </div>
        {activeNav === 0 && <AddressMap />}
        {activeNav === 1 && <RouteControl />}
        {activeNav === 2 && <Statis />}     
        {activeNav === 3 && <Profile />} </div>     
    )
  }
export default BrokerPanel