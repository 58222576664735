import React, { useEffect, useState } from 'react'
import Chart from '../Chart';
import authAxios from '../../utils';
import { getPackageDataUrl } from '../../constants';
import TagPdf from './TagPdf';
import RecordTable from '../RecordTable';

const packageColumns = [
    {
        id: 'created_at',
        sort: true,
        disablePadding: false,
        label: 'Created at',
    },
    {
        id: 'tag',
        sort: true,
        disablePadding: false,
        label: 'Tag',
    },
    {
        id: 'status',
        sort: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'customer',
        sort: true,
        disablePadding: false,
        label: 'Customer',
    },
    {
        id: 'address',
        sort: true,
        disablePadding: false,
        label: 'Address',
    },
    {
        id: 'business',
        sort: true,
        disablePadding: false,
        label: 'Business',
    },
];  
const Packages = () => {
    const [showTable, setShowTable] = useState(true);
    const [packages, setPackages] = useState([]);

    useEffect(()=>{
        authAxios.get(getPackageDataUrl)
        .then(response => {
            let packages = [];
            for (let i=0; i<response.data.length; i++) {
                let date = new Date(response.data[i].created_at).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    });
                let updated_date = new Date(response.data[i].updated_at).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    });
                let row = {
                    tag: response.data[i].unique_id,
                    created_at: date,
                    updated_at: updated_date,
                    status: response.data[i].status_display,
                    status_id: response.data[i].status,
                    memo: response.data[i].memo,
                    photo: response.data[i].delivered_photo,
                    customer: response.data[i].customer_name,
                    customer_phone: response.data[i].customer_phone,
                    address : response.data[i].address,
                    business: response.data[i].business,
                    qrcode: response.data[i].qrcode,
                }
                packages.push(row);
            }
            setPackages(packages);
        })
        .catch(err=>console.log(err));  
    }, []);

    const [showTags, setShowTags] = useState(false);
  return (
    <div class="flex flex-col-reverse lg:flex-row  gap-2 mt-4 mb-8 w-full h-full">
        <div class="w-full lg:w-1/4 flex flex-col gap-2 flex-shrink-0 flex-grow-0 pr-2 border-r border-gray-200">
            <div class="flex items-center my-2 justify-center gap-2">
                <input type="radio" checked={showTable} onChange={()=>setShowTable(true)} name="table-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" /> 
                <label for="default-radio-1" class="default_text">Statis</label>
                <input type="radio" checked={!showTable} onChange={()=>setShowTable(false)} name="chart-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
                <label for="default-radio-2" class="default_text">Chart</label>
            </div>
            <button class="default_btn" type="submit" onClick={()=>setShowTags(!showTags)}>{showTags ? "Hide Package Tags" : "Show Pakcage Tags"}</button>
            {showTags && <TagPdf packages={packages.filter(item=>item.status_id==='RA')} />}
        </div>
        <div className='w-full overflow-y-auto flex-grow'>
            {showTable ? (
                <RecordTable table="package" rows={packages} columns={packageColumns} />
            ) : (
                <Chart />
            )}

        </div>
    </div>  
    )
}

export default Packages