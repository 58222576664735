import axios from 'axios';
import {useRef, React, useState} from 'react';
import { passwordResetUrl } from '../constants';
import { useNavigate } from 'react-router-dom'
import { emailRegex } from '../utils';

const ForgetPassword = () => {
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const [err, setErr] = useState(null);

    const onResetPassword = ()=> {
        let email = inputRef.current.value;
        if (!emailRegex.test(email))  {
            setErr("Invalid email address");
            return;
        }
        axios.post(passwordResetUrl, {email: email})
        .then(response=>{
            navigate("/");
        })
        .catch(err=>{
            setErr(err)
        });
    };
  return (
    <div class="flex flex-col gap-2 mt-4 lg:mt-8 items-center justify-center">
        {err && <p class="font-bold text-red-500 text-lg">{err}</p>}
        <div  class="flex w-[300px] flex-col gap-2 items-center">
            <label for="email" class="default_text">Email:</label>
            <input id="email" name="email" type="email" autocomplete="email" ref={inputRef} required class="w-full default_input"/>
            <button class="default_btn w-full mt-2" onClick={()=>onResetPassword()}>Submit</button>
        </div>
    </div>
  )
}

export default ForgetPassword