import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authCheckState } from "./store/actions/auth";
import BusinessPanel from "./pages/BusinessPanel";
import DriverPanel from "./pages/DriverPanel";
import BrokerPanel from "./pages/BrokerPanel";
import {APIProvider} from '@vis.gl/react-google-maps';
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import DriverAppPrivacyPolicy from "./pages/DriverAppPrivacyPolicy";
import AxiosInterceptor from "./components/AxiosInterceptor";
function App() {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch((authCheckState()));
  }, []);

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
      <BrowserRouter>
      <AxiosInterceptor>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          {/*<Route path="/signup" element={<Signup />} />*/}
          <Route path="/driver-panel" element={<DriverPanel />} />
          <Route path="/business-panel" element={<BusinessPanel />} />
          <Route path="/broker-panel" element={<BrokerPanel />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password/:uid/:token" element={<ResetPassword />}/>
          <Route path="/driver-app-privacy-policy" element={<DriverAppPrivacyPolicy />} />
        </Routes>
        </AxiosInterceptor>
      </BrowserRouter>
    </APIProvider>
  );
}

export default App;