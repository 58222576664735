import { useParams } from 'react-router-dom';
import axios from 'axios';
import {useRef, React, useState} from 'react';
import { pwdResetConfirmUrl } from '../constants';
import { useNavigate } from 'react-router-dom'
import { passwordRegex } from '../utils';

const ResetPassword = () => {
    const [err, setErr] = useState(null);
    const params = useParams();
    const pwd1Ref = useRef(null);
    const pwd2Ref = useRef(null);
    const navigate = useNavigate();

    // yuo can find all params from here
    const onSubmit = ()=> {
        let pwd1 = pwd1Ref.current.value;
        let pwd2 = pwd2Ref.current.value;

        if (pwd1 !== pwd2) {
            setErr("Passwords don't match!");
            return;
        }
        
        if (!passwordRegex.test(pwd1)) {
            setErr("Password must be at least 8 characters long and includes at least one digit, one letter, and one symbol!");
            return;
        }
        
        axios.post(pwdResetConfirmUrl + params.uid +"/" + params.token, {
            "new_password1": pwd1,
            "new_password2": pwd2,
            "uid": params.uid,
            "token": params.token
        })
        .then(response=>{
            navigate("/");
        })
        .catch(response=>{
            setErr(response.data);
        });
    };

    return (
        <div class="flex flex-col gap-4 mt-4 lg:mt-8 items-center justify-center">
            {err && <p class="font-bold text-red-500 text-lg">{err}</p>}
            <div  class="flex w-[300px] flex-col gap-2 items-center justify-center">
                <div class="flex flex-col gap-2 w-full">
                    <label for="password" class="default_text">Password</label>
                    <input id="password1" name="password1" type="password" ref={pwd1Ref} autocomplete="current-password" required class="w-full default_input" />
                </div>
                <p class="text-sm text-gray-400">Password must be at least 8 characters long and includes at least one digit, one letter, and one symbol!</p>
                <div class="flex flex-col gap-2 w-full">
                    <label for="password" class="default_text">Confirm password</label>
                    <input id="password2" name="password2" type="password" ref={pwd2Ref} autocomplete="current-password" required class="w-full default_input" />
                </div>
                <button class="default_btn w-full mt-4" onClick={()=>onSubmit()}>Submit</button>
            </div>
        </div>
      )
}

export default ResetPassword