import React from 'react'
import { MdOutlineLocalPhone, MdOutlineMail } from "react-icons/md";

const Footer = () => {
  return (
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 border-t border-gray-200">
        <div class="flex h-16 items-center justify-between">
            <p className='text-sm text-gray-500'>
                @2024 AuspDelivery LLC
            </p>
            <div class="flex justify-end items-center gap-4">
                {/*
                <div class="flex gap-1 items-center">
                    <MdOutlineLocalPhone />
                    <p className='text-sm text-gray-500'>510-219-xxx</p>
                </div> */}
                <div class="flex gap-2 items-center">
                    <p className='text-sm text-gray-500'>Contact us: </p>
                    <p className='text-sm text-gray-500'>contact@aupsdelivery.com</p>
                    <MdOutlineMail />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer