import React from 'react'
import { logout, joinGroup } from '../store/actions/auth';
import { useDispatch } from 'react-redux';

const Logout = () => {
    const dispatch = useDispatch();
    const handleLogoutClick = () => {
        dispatch(logout());
        dispatch(joinGroup(0));
    }
  return (
    <>
        <button type="button" onClick={()=>handleLogoutClick()} class="default_btn w-[150px]">
            <div>Logout</div>
        </button>  
    </>
  )
}

export default Logout;