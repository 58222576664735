import axios from "axios";
import {  refreshTokenUrl } from "../constants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import authAxios from "../utils";
import { useDispatch } from "react-redux";
import { logout } from "../store/actions/auth";

const AxiosInterceptor = ({ children }) => {
    const [isSet, setIsSet] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // Define request/response and error interceptors
    const reqResInterceptor = (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    };

    const reqErrInterceptor = async (error) => Promise.reject(error);

    const resResInterceptor = async (response) => {
        // Handle successful responses as needed
        return response;
    }

    const resErrInterceptor = async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            let refreshToken = localStorage.getItem('refreshToken');
            const response = await axios.post(refreshTokenUrl, { refresh: refreshToken });
            const token = response.data.access;
            refreshToken = response.data.refresh;
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
    
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          } catch (error) {
            //localStorage.removeItem('token');
            //localStorage.removeItem('refreshToken');
            dispatch(logout());
            navigate("/")
          }
        }
    
        return Promise.reject(error);
    }

    useEffect(() => {
        const reqInterceptor = authAxios.interceptors.request.use(reqResInterceptor, reqErrInterceptor);
        const resInterceptor = authAxios.interceptors.response.use(resResInterceptor, resErrInterceptor);
        setIsSet(true);
        return () => {
            authAxios.interceptors.request.eject(reqInterceptor);
            authAxios.interceptors.response.eject(resInterceptor);
        }     
    }, []);

    return isSet && children;
}

export default AxiosInterceptor;