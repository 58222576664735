import { useNavigate } from 'react-router-dom'
import { useRef, React, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { user_group, getUserInfoUrl, changePasswordUrl, deleteAccountUrl } from '../constants'
import authAxios from '../utils'
import { emailRegex, passwordRegex, phoneRegex } from '../utils'
import { confirmAlert } from 'react-confirm-alert'; 
import { logout } from '../store/actions/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const Profile = () => {
    const [ error, setError ] = useState("");
    const group =  useSelector((state) => state.auth.group);
    const navigate = useNavigate();
    const [passwordChange, setPasswordChange] = useState(false);
    const pwd1Ref = useRef(null);
    const pwd2Ref = useRef(null);
    const dispatch = useDispatch();

    const [ signupForm, setSignupForm ] = useState({
        first_name: "", last_name: "", email:"",
        phone_number: "", driver_license: "", vehicle_plate_number: "", business_name: ""
    });

    useEffect(() => {
        authAxios.get(getUserInfoUrl)
        .then(response => {
            setSignupForm({
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                email: response.data.email,
                // remove "+1" from the beginning of phone number
                phone_number: response.data.profile.phone_number.slice(2),
                driver_license: response.data.profile.driver_license,
                vehicle_plate_number: response.data.profile.vehicle_plate_number,
                business_name: response.data.profile.business_name
            })
        })
        .catch(err => console.log(err));
    }, []);

    const handleChange = e => {
        setSignupForm(signupForm => ({
            ...signupForm,
            [e.target.name]: e.target.value
        }));
    };
    const handleSubmit = (e)=>{
        e.preventDefault();
        const data = {
            signup_data: {
                first_name: "", 
                last_name: "", 
                email:"", 
                profile: {
                    phone_number: "", 
                    driver_license: "", 
                    vehicle_plate_number: "", 
                    business_name: ""
                }}};
        data.signup_data.first_name = signupForm.first_name;
        data.signup_data.last_name = signupForm.last_name;
        if (!emailRegex.test(signupForm.email)) {
            setError("Invalid email address");
            return;
        }
        data.signup_data.email = signupForm.email;
        if (signupForm.phone_number !== "") {
            if (!phoneRegex.test(signupForm.phone_number)) {
                setError("Invalid phone number");
                return;
            }
            // add "+1" to the beginning of phone number
            data.signup_data.profile.phone_number = "+1"+signupForm.phone_number;
        }
        data.signup_data.profile.driver_license = signupForm.driver_license;
        data.signup_data.profile.vehicle_plate_number = signupForm.vehicle_plate_number;
        data.signup_data.profile.business_name = signupForm.business_name;
        
        authAxios.post(getUserInfoUrl, {user: data})
        .then(res=>{
            navigate("/");
        })
        .catch(err=>console.log(err));
    };

    const handlePasswordChange = ()=> {
        if (!passwordChange) {
            setPasswordChange(!passwordChange);
            return;
        }
        setPasswordChange(!passwordChange);

        let pwd1 = pwd1Ref.current.value;
        let pwd2 = pwd2Ref.current.value;

        if (pwd1 !== pwd2) {
            setError("Passwords don't match!");
            return;
        }
        
        if (!passwordRegex.test(pwd1)) {
            setError("Password must be at least 8 characters long and includes at least one digit, one letter, and one symbol!");
            return;
        }
        
        authAxios.post(changePasswordUrl, {
            "new_password1": pwd1,
            "new_password2": pwd2
        })
        .then(response=>{
            navigate("/");
        })
        .catch(response=>{
            setError(response.data);
        });
    };

    const onRemoveAccount = () => {
        confirmAlert({
            title: 'Warning',
            message: 'Are you sure you want to delete your account?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    authAxios
                      .post(deleteAccountUrl)
                      .then(response => {
                        dispatch(logout());
                      })
                      .catch(response => {
                        setError(response.data);
                      });
                  },
              },
              {
                label: 'No',
                onClick: ()=>{},
              }
            ]
          });
      };

  return (
    <div class="flex flex-col gap-2 mt-4 mb-8 w-full mx-auto md:w-1/2 h-full">
        {error && <p class="font-bold text-red-500 text-lg">{error}</p>}
        <form class="grid grid-cols-1 md:grid-cols-2 place-content-center gap-4" action="#" method="POST">
        <div>
            <label for="first_name" class="default_text">First Name</label>
            <div class="mt-2">
            <input id="first_name" defaultValue={signupForm.first_name} onChange={(e)=>handleChange(e)} name="first_name" type="text" required class="w-full default_input" />
            </div>
        </div>
        <div>
            <label for="last_name" class="default_text">Last Name</label>
            <div class="mt-2">
            <input id="last_name" defaultValue={signupForm.last_name} onChange={(e)=>handleChange(e)} name="last_name" type="text" required class="w-full default_input" />
            </div>
        </div>
        <div>
            <label for="email" class="default_text">Your email</label>
            <div class="mt-2">
            <input id="email" defaultValue={signupForm.email} onChange={(e)=>handleChange(e)} name="email" type="email" autocomplete="email" required class="w-full default_input"/>
            </div>
        </div>
        <div>
            <label for="phone_number" class="default_text">Phone#</label>
            <div class="mt-2">
            <input id="phone_number" defaultValue={signupForm.phone_number} onChange={(e)=>handleChange(e)} name="phone_number" type="text" placeholder="5104567829" required class="w-full default_input" />
            </div>
        </div>
        {group === user_group.Driver && (
        <>
            <div>
                <label for="driver_license" class="default_text">Driver License</label>
                <div class="mt-2">
                    <input id="driver_license" defaultValue={signupForm.driver_license} onChange={(e)=>handleChange(e)} name="driver_license" type="text" required class="w-full default_input" />
                </div>
            </div>
            <div>
                <label for="vehicle_plate_number" class="default_text">Vehicle Plate Number</label>
                <div class="mt-2">
                    <input id="vehicle_plate_number" defaultValue={signupForm.vehicle_plate_number} onChange={(e)=>handleChange(e)} name="vehicle_plate_number" type="text" required class="w-full default_input" />
                </div>
            </div>
        </>
        )}
        {group === user_group.Business && (
            <div>
                <label for="business_name" class="default_text">Business Name</label>
                <div class="mt-2">
                    <input id="business_name" defaultValue={signupForm.business_name} onChange={(e)=>handleChange(e)} name="business_name" type="text" required class="w-full default_input" />
                </div>
            </div>
        )}        
        <button type="submit" onClick={(e)=>handleSubmit(e)} class="default_btn w-full md:col-span-2">Update</button>
        </form>
        <div class="mt-4">
         {passwordChange && <div class="grid grid-cols-1 gap-4 md:grid-cols-2 border-2 my-2 p-2 border-gray-100 rounded-md">
            <p class="md:col-span-2 text-sm text-gray-400">Password must be at least 8 characters long and includes at least one digit, one letter, and one symbol!</p>
            <div>
                <label for="password" class="default_text">New password</label>
                <div class="mt-2">
                <input id="password1" ref={pwd1Ref} name="password1" type="password" autocomplete="current-password" required class="w-full default_input" />
                </div>
            </div>
            <div>
                <label for="password" class="default_text">Confirm new password</label>
                <div class="mt-2">
                <input id="password2" ref={pwd2Ref} name="password2" type="password" autocomplete="current-password" required class="w-full default_input" />
                </div>
            </div>
         </div>}
         <button type="submit" onClick={(e)=>handlePasswordChange(e)} class="default_btn w-full">{passwordChange ?"Submit":"Change Password"} </button>
        </div>
        <div class="my-4">
        <button type="submit" onClick={(e)=>onRemoveAccount(e)} class="default_btn w-full">Delete Account</button>  
        </div>
        </div>
    )
}

export default Profile