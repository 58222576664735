import { React, useRef, useEffect, useState } from 'react';
import { authAxios, adjustColor } from '../../utils';
import {Map, useMapsLibrary, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';
import Direction from './Direction';
import { notifyDriverRouteAssignURL, updateRouteAddrOrderURL, getActiveRoutesUrl, getDriverListUrl, updateRouteURL } from '../../constants';
import RouteAssign from './RouteAssign';

const SELECT_SOURCE = {
    source_route: 0,
    start_addr: 1,
    end_addr: 2,
};
const baseMarkerColor = '#FFFF00';
const centerCoord = {lat: 37.3881565, lng: -122.0027096};


const RouteControl = () => {
    const inputRef = useRef(null);
    const [showRoutes, setShowRoutes] = useState(false);
    const [routeAddrCoordinates, setRouteAddrCoordinates] = useState([]);
    const [displayedRoutes, setDisplayedRoutes] = useState({
        optimize: true,  // always true now, but should optimize only when route is changed
        routes: []});
    const [routes, setRoutes] = useState([]);
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionService] = useState(null);
    const [reorderedRoute, setReorderedRoute] = useState(0);
    const [drivers, setDrivers] = useState([]);
    const [routeChange, setRouteChange] = useState({
        source_route: -1, 
        start_addr: -1, 
        end_addr: -1, 
    });
    
    // get new routes whose directions are not set yet 
    useEffect(()=>{
        authAxios
        .get(getActiveRoutesUrl)
        .then((response => {
            let routes = [];
            for (let i=0; i<response.data.length; i++) {
                if (response.data[i].status === 'DF') {
                    continue;
                }
                routes.push(response.data[i]);
            }
            setRoutes(routes);        
        }))
        .catch(err => {
          console.log(err);
        });
    }, []);

    useEffect(() => {
        authAxios
        .get(getDriverListUrl)
        .then((response => {
            setDrivers(response.data.filter(d=>d.verified===true));
        }))
        .catch(err => {
          console.log(err);
        });
    }, []);

    // after generating directions for all routes, update db
    useEffect(()=>{
        if (reorderedRoute !== 0 && reorderedRoute === routes.length) {
            // update route DB
            authAxios.post(updateRouteAddrOrderURL, {routes: routes})
            .then((response => {
                setRoutes(response.data);
            }))
            .catch(error => {
                console.log(error);
            })

        }
    }, [reorderedRoute]);

    useEffect(()=>{
        if (!routesLibrary) return;
        setDirectionService(new routesLibrary.DirectionsService());
    }, [routesLibrary]);

    // do recursion to get the direction for each route to avoid sync problem.
    const findRouteRecursion = (k) => {
        if (k >= routes.length) return;
        if ( routes[k].status !== 'AA') {
            setReorderedRoute(k+1);
            findRouteRecursion(k+1);
            return;
        }
        const len = routes[k].intermediate_addresses.length;
        const waypts = [];      
        var start = {};
        var end = {};
        var start_id = -1;
        var end_id = -1;
        var waypt_ori = [];
        for (let i = 0; i < len; i++) {
            if (routes[k].intermediate_addresses[i].id ===  routes[k].start_addr) {
                start.lat = parseFloat(routes[k].intermediate_addresses[i].lat);
                start.lng = parseFloat(routes[k].intermediate_addresses[i].lng);
                start_id = i;
                continue;
            }
            if (routes[k].intermediate_addresses[i].id ===  routes[k].end_addr) {
                end.lat = parseFloat(routes[k].intermediate_addresses[i].lat);
                end.lng = parseFloat(routes[k].intermediate_addresses[i].lng);
                end_id = i;
                continue;
            }
            waypt_ori.push(i);
            waypts.push({
                location: {lat: parseFloat(routes[k].intermediate_addresses[i].lat), lng: parseFloat(routes[k].intermediate_addresses[i].lng)},
                stopover: true,
            });
        }

        directionsService.route({
            origin: start,
            destination: end,
            provideRouteAlternatives: false,
            travelMode: 'DRIVING',
            waypoints: waypts,
            optimizeWaypoints: true,
        }).then(response => {
            let order = [];
            order.push(start_id);
            for (let i=0; i<response.routes[0].waypoint_order.length; i++) {
                order.push(waypt_ori[response.routes[0].waypoint_order[i]]);
            }
            order.push(end_id);
            let ordered_addresses = order.map(index => routes[k].intermediate_addresses[index]);
            //for (let i=0; i<ordered_addresses.length; i++) {
            //    ordered_addresses[i].order_in_route = i+1;
            //}
            routes[k].intermediate_addresses = ordered_addresses;
            setReorderedRoute(k+1);
            findRouteRecursion(k+1);
        }).catch(err => {
            console.log(err);
        });
    };

    const handleDirectionGeneration = () => {
        // reorder addresses in each cluster to get the optimized route.
        if (!routesLibrary) return;
        if (!directionsService) return;
        findRouteRecursion(0);
    }

    // prepare cluster map data
    useEffect(()=>{
        if (!routes || routes.length === 0 || routes[0].length ===0) {
            return;
        }
        let X = [];
        for (let i=0; i<routes.length; i++) {
            X[i] = {id: routes[i].name, coordinates: []};
            for (let j=0; j<routes[i].intermediate_addresses.length; j++) {
                X[i].coordinates.push({
                    lat: parseFloat(routes[i].intermediate_addresses[j].lat), 
                    lng: parseFloat(routes[i].intermediate_addresses[j].lng)
                });
            }
        }
        setRouteAddrCoordinates(X);
    }, [routes]);

    const filterRoutes = (e) => {
        e.preventDefault();

        let filter = inputRef.current.value;
        if (filter === "") {
            let X = [];
            for (let i=0; i<routes.length; i++) {
                X[i] = {id: routes[i].name, coordinates: []};
                for (let j=0; j<routes[i].intermediate_addresses.length; j++) {
                    X[i].coordinates.push({
                        lat: parseFloat(routes[i].intermediate_addresses[j].lat), 
                        lng: parseFloat(routes[i].intermediate_addresses[j].lng)
                    });
                }
            }
            setShowRoutes(false);     
            setRouteAddrCoordinates(X);   
        } else {
            let route_names = filter.split(',');
            var X = [];
            setShowRoutes(true); 
            //X.splice(0, X.lenght);
            for (let i=0; i<route_names.length; i++) {
                let displayed_route = routes.find(cluster => cluster.name === parseInt(route_names[i]));
                if (displayed_route !== undefined) {
                    const r = Object.assign({}, displayed_route);
                    X.push(r);
                }
            }
            setDisplayedRoutes({optimize: true, routes: X});
        }
        let rc = {
            source_route: -1, 
            start_addr: -1, 
            end_addr: -1
        };
        rc.source_route = routeChange.source_route;

        setRouteChange(rc);
    }

    // Direction component returns the new address order for one route
    const updateRouteAddrOrderCallback = (order, route) => {
        let new_addrs = order.map(index => route.intermediate_addresses[index]);
        var i = routes.findIndex(x => x.name === route.name);
        //console.log("previous addresses ->");
        //console.log(routes[i].intermediate_addresses);
        //console.log("updated addresses ->");
        //console.log(new_addrs);
        let route_nochange = false; 
        if (new_addrs.length === routes[i].intermediate_addresses.length) {
            let j = 0;
            for (; j<new_addrs.length; j++) {
                if (new_addrs[j].id !== routes[i].intermediate_addresses[j].id) {
                    break;
                }
            }
            if (j === new_addrs.length) {
                route_nochange = true;
            }
        }

        if (route_nochange) return;

        let new_route = Object.assign({}, route);
        new_route.intermediate_addresses = new_addrs;
 
        // update the route db
        authAxios.post(updateRouteURL, {route: new_route})
        .then(response => {
            let new_routes = structuredClone(routes);
            new_routes[i] = new_route;
            setRoutes(new_routes); 
        })
        .catch(err => console.log(err));  
    }

    const [startSelectOption, setStartSelectOption] = useState("N/A");
    const [endSelectOption, setEndSelectOption] = useState("N/A");
    const handleSelectChange = (e, source) => {
        switch (source) {
            case SELECT_SOURCE.source_route: 
                if (e.target.value !== "N/A") {
                    let route = routes.find(route => route.name === parseInt(e.target.value));
                    if (route !== undefined) {
                        let rc = {
                            source_route: parseInt(e.target.value), 
                            start_addr: -1, 
                            end_addr: -1, 
                        }
                        setRouteChange(rc);
                        setShowRoutes(true); 
                        let r = Object.assign({}, route);
                        setDisplayedRoutes({optimize: true, routes: [r]});
                        setStartSelectOption("N/A");
                        setEndSelectOption("N/A");
                    }
                } else {
                    setStartSelectOption(e.target.value);
                    setRouteChange({...routeChange, source_route: -1});
                    setShowRoutes(false); 
                }
            break;
            case SELECT_SOURCE.start_addr:
                if (e.target.value !== "N/A") {
                    var start_id = e.target.value.charCodeAt(0)-65;
                    let route = routes.find(x => x.name === routeChange.source_route);
                    setRouteChange({...routeChange, start_addr: route.intermediate_addresses[start_id].id});
                } else {
                    setRouteChange({...routeChange, start_addr: -1});
                }                
                setStartSelectOption(e.target.value); 
            break;
            case SELECT_SOURCE.end_addr: 
                if (e.target.value !== "N/A") {
                    var end_id = e.target.value.charCodeAt(0)-65;
                    let route = routes.find(x => x.name === routeChange.source_route);
                    setRouteChange({...routeChange, end_addr: route.intermediate_addresses[end_id].id});
                } else {
                    setRouteChange({...routeChange, end_addr: -1});
                }    
                setEndSelectOption(e.target.value);
            break;
            default: break;
        }
    };

    // apply changes in the route 
    const adjustRoute = () => {
        if (routeChange.source_route === -1) {
            return;
        }
        var displayed_routes = [];
        let route =routes.find(x => x.name === routeChange.source_route);

        let new_route = structuredClone(route);
        if (routeChange.start_addr !== -1) {
            //new_route.start_addr = routeChange.start_addr ;
            new_route.start_addr = routeChange.start_addr;
        }
        if (routeChange.end_addr !== -1 && routeChange.end_addr !== routeChange.start_addr) {
            new_route.end_addr = routeChange.end_addr ;
        }
        /*if (routeChange.removed_addr.length > 0 
            && routeChange.target_route !== -1 
            && routeChange.target_route !== routeChange.source_route) {
            let target_route = routes.find(x => x.name === routeChange.target_route);
            let new_target = structuredClone(target_route);
            let indices = []
            for (let i=0; i<routeChange.removed_addr.length; i++) {
                let j = new_route.intermediate_addresses.findIndex(x => x.id === routeChange.removed_addr[i]);
                new_target.intermediate_addresses.push(new_route.intermediate_addresses[j]);
                indices.push(j);
            }
            indices.sort();
            for (let i=indices.length-1; i>=0; i--) {
                new_route.intermediate_addresses.splice(indices[i], 1);
            }
            new_route.start_addr =  new_route.intermediate_addresses[0].id;
            new_route.end_addr = new_route.intermediate_addresses[new_route.intermediate_addresses.length-1].id;
            
            displayed_routes.push(new_target);
        }*/
        
        displayed_routes.push(new_route);
        setDisplayedRoutes({optimize: true, routes: displayed_routes});
        setStartSelectOption("N/A");
        setEndSelectOption("N/A");
    }; 
    
    const [showForm, setShowForm] = useState(0);
    const notifyDriverCallback = (driver_assigned_routes) => {
        authAxios.post(notifyDriverRouteAssignURL, {driver_assigned_routes: driver_assigned_routes})
            .then((response)=> {
                setRoutes(response.data);
            })
            .catch(err=>console.log(err));
    };

    return (
    <div class="flex flex-col-reverse lg:flex-row gap-2 mt-4 mb-8 w-full h-full">
        <div class="lg:w-1/4 w-full flex flex-col gap-2 flex-shrink-0 flex-grow-0 pr-2 border-r border-gray-200">
            {showForm === 0 ? (
                <>
                <input id="numbers" name="numbers" type="text" ref={inputRef} required class="default_input" placeholder='1,2,3'/>
                <button class="active_btn my-2" type="submit" onClick={(e)=>filterRoutes(e)}>Show</button>
                </>
            ) : (
                <button class="default_btn my-2" type="submit" onClick={()=>setShowForm(0)}>Show Routes in Map</button>   
            )}
            <hr/>
            {showForm === 1? (
                <>
                <button class="active_btn my-2 w-full" type="submit" onClick={handleDirectionGeneration}>Generate</button>
                <div class="w-full bg-gray-200 rounded-full h-5 dark:bg-gray-700">
                    <div class="bg-orange-400 h-5 rounded-full" style={{width: `${ Math.trunc(reorderedRoute * 100 / routes.length ) }%`}}></div>
                </div>
                </>
            ) : (
                <button class="default_btn my-2 w-full" type="submit" onClick={()=>setShowForm(1)}>Generate Direction</button>
            )}
            <hr/>
            {showForm === 2 ? (
            <>
            {<RouteAssign routes={routes.filter(r=>r.status==='DC')} drivers={drivers} callback={notifyDriverCallback}/>}
            </>
            ) : (
                <button class="default_btn my-2" type="submit" onClick={()=>setShowForm(2)}>Assign Routes to Driver</button>
            )}
            <hr />
            {showForm === 3 ? (
                <>
                <div class="flex items-center justify-between gap-2 my-2">
                    <label for="select-route-1" class="default_text text-nowrap">Route:</label>
                    <select onChange={(e)=>handleSelectChange(e, SELECT_SOURCE.source_route)} id="select-route-1" class="default_text w-1/2 default_select">
                        <option selected>N/A</option>
                        {routes && routes.length > 0 && routes.map((route, index) => (
                            <option key={index}>{route.name}</option>
                        ))}
                    </select>    
                </div>
                <div class="flex items-center justify-between gap-2 my-2">
                    <label for="select-start-addr" class="default_text text-nowrap">Start:</label>
                    <select value={startSelectOption} onChange={(e)=>handleSelectChange(e, SELECT_SOURCE.start_addr)} id="select-start-addr" class="default_text w-1/2 default_select">
                        <option value="N/A" selected>N/A</option>
                        {routeChange.source_route !== -1 && routes.find(x => x.name === routeChange.source_route).intermediate_addresses.map((a, index) => (
                            <option key={index} value={String.fromCharCode(65+index)}>{String.fromCharCode(65+index)}</option>
                        ))}
                    </select>    
                </div> 
                <div class="flex items-center justify-between gap-2 my-2">
                    <label for="select-end-addr" class="default_text text-nowrap">End:</label>
                    <select value={endSelectOption} onChange={(e)=>handleSelectChange(e, SELECT_SOURCE.end_addr)} id="select-end-addr" class="default_text w-1/2 default_select">
                        <option value="N/A" selected>N/A</option>
                        {routeChange.source_route !== -1 && routes.find(x => x.name === routeChange.source_route).intermediate_addresses.map((a, index) => (
                            <option key={index} value={String.fromCharCode(65+index)}>{String.fromCharCode(65+index)}</option>
                        ))}
                    </select>    
                </div> 
                <button class="active_btn my-2" type="submit" onClick={()=>adjustRoute()}>Change</button>
                </>
            ) : (
                <button class="default_btn my-2" type="submit" onClick={()=>setShowForm(3)}>Change Start/End</button>
            )}

        </div>
        <div className='w-full h-full flex-grow border-indigo-400 border'>
            {showRoutes && 
                <Map
                    mapId='ROUTES_MAP_ID'
                    defaultZoom={10}
                    defaultCenter={ centerCoord }
                    key={new Date()}
                    >
                    {displayedRoutes && displayedRoutes.routes.map((route, index) => {
                        return (
                            <Direction key={index} 
                                data={route} 
                                index={index} 
                                size={displayedRoutes.routes.length}
                                optimize={displayedRoutes.optimize}
                                callback={updateRouteAddrOrderCallback}/>                                
                        )
                    })}
                </Map>            
            }
            {!showRoutes && 
                <Map
                    mapId='ALL_ADDRESSES_MAP_ID'
                    defaultZoom={10}
                    defaultCenter={ centerCoord }
                    >
                    {routeAddrCoordinates && routeAddrCoordinates.map((route, index) => {
                        return route.coordinates.map(coordinate => (
                            <AdvancedMarker position={coordinate}>
                                <Pin background={adjustColor(baseMarkerColor, 
                                                    Math.trunc(index/3+1)*Math.trunc(256/(Math.trunc(routeAddrCoordinates.length/3)+1))*(index % 3 === 2 ? 1 : -1),
                                                        index % 3)} 
                                    glyph={route.id.toString()} glyphColor={'#000'} borderColor={'#000'} />
                            </AdvancedMarker>                                       
                        ))
                        }
                    )}
                </Map>
            }
        </div>

    </div>
    )
}

export default RouteControl