import React, { useEffect, useState } from 'react'
import Chart from '../Chart';
import authAxios from '../../utils';
import { getActiveRoutesUrl } from '../../constants';
import RecordTable from '../RecordTable';


const routeColumns = [
    {
        id: 'id',
        sort: true,
        disablePadding: false,
        label: 'id',
    },
    {
        id: 'created_at',
        sort: true,
        disablePadding: false,
        label: 'Created at',
    },
    {
        id: 'status',
        sort: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'profit',
        sort: true,
        disablePadding: false,
        label: 'Profit',
    },
    {
        id: 'addreses',
        sort: false,
        disablePadding: false,
        label: 'Addresses',
    },
]

const RouteRecords = () => {
    const [data, setData] = useState([]);
    const [showTable, setShowTable] = useState(true);

    useEffect(()=>{
        authAxios.get(getActiveRoutesUrl)
        .then(response => {
            let routes = [];
            for (let i=0; i<response.data.length; i++) {
                if (response.data[i].status === 'DC') {
                    continue;
                }
                let date = new Date(response.data[i].created_at).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    });
                let row = {
                    id: response.data[i].id,
                    created_at: date,
                    status: response.data[i].status_display,
                    profit: response.data[i].profit.toString(),
                    addresses: response.data[i].intermediate_addresses,
                }
                routes.push(row);
            }
            setData(routes);
        })
        .catch(err=>console.log(err));
    }, []);

  return (
    <div class="flex flex-col-reverse lg:flex-row gap-2 mt-4 mb-8 w-full h-full">
        <div class="w-full lg:w-1/4 pr-2 border-r border-gray-200">
            <div class="flex items-center my-2 justify-center gap-2">
                <input type="radio" checked={showTable} onChange={()=>setShowTable(true)} name="table-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" /> 
                <label for="default-radio-1" class="default_text">Statis</label>
                <input type="radio" checked={!showTable} onChange={()=>setShowTable(false)} name="chart-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
                <label for="default-radio-2" class="default_text">Chart</label>
            </div>
        </div>
        <div className='w-full h-full flex-grow'>
            {showTable ? (
                <RecordTable table="route" rows={data} columns={routeColumns} />
            ) : (
                <Chart />
            )}

        </div>
    </div>  
    )
}


export default RouteRecords
