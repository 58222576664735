import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
const DriverAppPrivacyPolicy = () => {
    const title = 'AuspDelivery Driver App Privacty Policy';
    const summary = 'At AuspDelivery ("we," "our," or "us"), we value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and \n protect information through our mobile app ("App") used by drivers to sign up, log in, navigate routes, and deliver packages.'
    return (
    <div>
      <Header />
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 border-b border-gray-200">
        <div class="py-4 flex flex-col gap-4">
            <div class="text-2xl font-bold">{title}</div>
            <div>
                <div class="text-sm font-bold">Last Updated: October 21, 2024</div>
                <div  class="text-sm">
                    {summary}
                </div>
            </div>
            <div>
                <div class="text-md font-bold">1. Information We Collect</div>
                <div  class="text-sm">
                    <div>We collect the following personal information from you:</div>
                    <div class='pl-4'>Name: To identify you within our platform.</div>
                    <div class='pl-4'>Email Address: For communication, including notifications and account recovery.</div>
                    <div class='pl-4'>Phone Number: To facilitate communication with you regarding deliveries or account-related issues.</div>
                </div>
            </div>
            <div>
                <div class="text-md font-bold">2. How We Use Your Information</div>
                <div  class="text-sm">
                    <div>We use the information collected to:</div>
                    <div class='pl-4'>Create and manage your driver account.</div>
                    <div class='pl-4'>Assign delivery routes and facilitate navigation.</div>
                    <div class='pl-4'>Communicate with you about assignments or support inquiries.</div>
                    <div class='pl-4'>Ensure compliance with legal obligations.</div>
                </div>
            </div>
            <div>
                <div class="text-md font-bold">3. Information Sharing</div>
                <div  class="text-sm">
                    <div>We do not sell or rent your personal information. However, we may share your information with:</div>
                    <div class='pl-4'>Law Enforcement: If required by law or to protect our rights and safety.</div>
                </div>
            </div>
            <div>
                <div class="text-md font-bold">4. Data Retention</div>
                <div  class="text-sm">
                    <div>We retain your personal data only for as long as necessary to provide our services or comply with legal obligations. You can request deletion of your personal data from the app or by contacting us at aupsdelivery@gamil.com</div>
                </div>
            </div>
            <div>
                <div class="text-md font-bold">5. Your Rights</div>
                <div  class="text-sm">
                    <div>You have the right to:</div>
                    <div class='pl-4'>Access, update and delete your personal information.</div>
                    <div class='pl-4'>Request the deletion of your account and data (subject to legal obligations).</div>
                    <div class='pl-4'>Object to or restrict certain data processing activities.</div>
                </div>
            </div>
            <div>
                <div class="text-md font-bold">6. Security</div>
                <div  class="text-sm">
                    <div>We implement industry-standard security measures to protect your data. However, no method of transmission over the internet or method of storage is 100% secure. We encourage you to keep your login credentials safe.</div>
                </div>
            </div>
            <div>
                <div class="text-md font-bold">7. Changes to This Privacy Policy</div>
                <div  class="text-sm">
                    <div>We may update this Privacy Policy from time to time. When we make changes, we will notify you through the App or other means. Your continued use of the App indicates your acceptance of the updated policy.</div>
                </div>
            </div>
            <div>
                <div class="text-md font-bold">8. Contact Us</div>
                <div  class="text-sm">
                    <div>If you have any questions or concerns about this Privacy Policy, please contact us at:</div>
                    <div class="font-bold">AuspDelivery LLC</div>
                    <div>Email: aupsdelivery@gmail.com</div>
                </div>
            </div>
        </div>
      </div>
      <Footer />
    </div>  )
}

export default DriverAppPrivacyPolicy