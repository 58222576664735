import { React, useEffect, useState } from 'react'
import { PiArrowFatRightLight, PiArrowFatLeftLight } from "react-icons/pi";

const RouteAssign = ({routes, drivers, callback}) => {
    const [routeDriverCheck, setRouteDriverCheck] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(-1);

    useEffect(()=>{
        let rdc = JSON.parse(localStorage.getItem('rdc'));
        if (rdc === null || rdc.length === 0) {
            rdc = [];
            for (let i=0; i<routes.length; i++) {
                rdc.push({
                    route_id: routes[i].id, 
                    route_name: routes[i].name,
                    driver_id: null, 
                    checked: false
                });
            }
        }
        setRouteDriverCheck(rdc);
    }, []);

    const onRouteCheckChange = (id) => {
        let i = routeDriverCheck.findIndex(r => r.route_id === id);
        let rdc = [...routeDriverCheck];
        rdc[i].checked = !rdc[i].checked;
        setRouteDriverCheck(rdc);
    };

    const assignRoute2Driver = (reverse) => {
        if (selectedDriver === -1) return;
        let rdc = [...routeDriverCheck];
        for (let i=0; i<rdc.length; i++) {
            if (rdc[i].checked) {
                if (reverse && rdc[i].driver_id !== null) {
                    rdc[i].driver_id = null;
                    rdc[i].checked = false;
                } 
                if (!reverse && rdc[i].driver_id === null) {
                    rdc[i].driver_id = selectedDriver;
                    rdc[i].checked = false;
                }
            }
        }

        localStorage.setItem('rdc', JSON.stringify(rdc));
        setRouteDriverCheck(rdc);
    };

    const onNotifyDrivers = () => {
        let droutes = [...routes];
        for (let i=0; i<routeDriverCheck.length; i++) {
            let route = droutes.find(r=>r.id===routeDriverCheck[i].route_id);
            route.driver_id = parseInt(routeDriverCheck[i].driver_id);
            let driver = drivers.find(d=>d.id===parseInt(routeDriverCheck[i].driver_id));
            route.driver_name = driver.first_name+' '+driver.last_name;
            route.status = "DA";
        }
        callback(droutes);
        localStorage.removeItem('rdc');
    };

    return (
    <div class="w-full py-2 flex flex-col gap-2">
        <div class="flex justify-between items-center w-full">
            <div class="w-2/5 default_text text-center">Select Route</div>
            <div class="w-1/5"></div>
            <div class="w-2/5">
                <select value={selectedDriver}
                    onChange={e => setSelectedDriver(e.target.value)}
                    class="default_text default_select text-center w-full h-full"
                    >
                    <option value={-1}>Select Driver</option>
                    {drivers && drivers.length > 0 && drivers.map((driver, index) => 
                        <option key={index} value={driver.id}>{driver.first_name+' '+driver.last_name + '('+routeDriverCheck.filter(r=> parseInt(r.driver_id) === driver.id).length+')'}</option>
                    )}
                </select>  
            </div>         
        </div>

        <div class="flex justify-between w-full h-full">
            <div class="flex flex-col gap-2 w-2/5 h-[300px] overflow-y-scroll items-stretch border-2 border-gray-300 rounded-md">
            {routeDriverCheck.length > 0 && routeDriverCheck.filter(r=>r.driver_id===null).map((record, index)=>(
                <div class="flex gap-2 border-b-2 border-gray-300 p-2 shadow-md bg-clip-border rounded-md" key={index}>
                <input type="checkbox" onChange={()=>onRouteCheckChange(record.route_id)} checked={record.checked} class="mx-2 shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500" id="hs-default-checkbox" />
                <label for="hs-default-checkbox" class="text-sm text-gray-500 dark:text-neutral-400">{record.route_name}</label>
                </div>
            ))}
            </div>
            <div class="flex flex-col gap-2 px-2 w-1/5 justify-center">
                <div class="border-2 border-orange-300 rounded-lg hover:bg-green-200 h-[28px] grid place-items-center"
                    onClick={()=>assignRoute2Driver(false)}>
                    <PiArrowFatRightLight />
                </div>
                <div class="h-[28px] border-2 border-orange-300 rounded-lg  hover:bg-green-200 grid place-items-center"
                    onClick={()=>assignRoute2Driver(true)}>
                    <PiArrowFatLeftLight />
                </div>
            </div>
            <div class="flex flex-col gap-2 w-2/5 h-[300px] overflow-y-scroll items-stretch border-2 border-gray-300 rounded-md">
            {routeDriverCheck.length > 0 && routeDriverCheck.filter(r=>r.driver_id===selectedDriver).map((record, index)=>(
                <div class="flex gap-2 border-b-2 border-gray-300 p-2 shadow-md bg-clip-border rounded-md" key={index}>
                <input type="checkbox" onChange={()=>onRouteCheckChange(record.route_id)} checked={record.checked} class="mx-2 shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500" id="hs-default-checkbox" />
                <label for="hs-default-checkbox" class="text-sm text-gray-500 dark:text-neutral-400">{record.route_name}</label>
                </div>
            ))}
            </div>
        </div>
        <div class="w-full"><button class="w-full mt-2 default_btn" disabled={routeDriverCheck.filter(r=>r.driver_id===null).length!==0} onClick={()=>onNotifyDrivers()}>{routeDriverCheck.filter(r=>r.driver_id===null).length!==0?(routeDriverCheck.filter(r=>r.driver_id===null).length)+" routes not assigned":"Notify Drivers"}</button></div>
    </div>
    )
}

export default RouteAssign