import { React, useRef, useEffect, useState } from 'react';
import { clusterAddressesUrl, unroutedAddressesUrl, moveAddressBetweenRoutesUrl, getAddrCoordinateUrl } from '../../constants';
import { authAxios, adjustColor } from '../../utils';
import {Map, AdvancedMarker, Pin, InfoWindow } from '@vis.gl/react-google-maps';

const baseMarkerColor = '#FFFF00';

const AddressMap = () => {
    const [ file, setFile ] = useState();
    const [routeAddrCoordinates, setRouteAddrCoordinates] = useState([]);
    const [routeAddresses, setRouteAddresses] = useState([[]]);
    const [centerCoord, setCenterCoord] = useState({lat: 37.3881565, lng: -122.0027096});
    const [ clusterResult, setClusterResult ] = useState([[]]);
    const [ invalidAddresses, setInvalidAddresses] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);

    // fetch all unrouted addresses while mounted
    useEffect(()=>{
        //let data = localStorage.getItem("addresses") ? 
        //                JSON.parse(localStorage.getItem("addresses")) : []; 
        authAxios.get(unroutedAddressesUrl)
        .then((response)=> {
            setRouteAddresses(response.data);
            let X = [{cluster: 0, addresses: [{}]}];
            for (let i=0; i<response.data.length; i++) {
                X[0].addresses.push({
                    id: response.data[i].id,
                    coordinate: {
                        lat: parseFloat(response.data[i].lat), 
                        lng: parseFloat(response.data[i].lng)
                    }
                });
            }
            setRouteAddrCoordinates(X);       
        })
        .catch(err => console.log(err));
    }, []);
    
    // addresses are clustered as routes, handle them here
    useEffect(()=>{
        if (!clusterResult || clusterResult.length === 0 || clusterResult[0].length ===0) {
            return;
        }
        let X = [];
        for (let i=0; i<clusterResult.length; i++) {
            X[i] = {cluster: clusterResult[i].name, addresses: [{}]};
            for (let j=0; j<clusterResult[i].intermediate_addresses.length; j++) {
                X[i].addresses.push({
                    id: clusterResult[i].intermediate_addresses[j].id,
                    coordinate: {
                        lat: parseFloat(clusterResult[i].intermediate_addresses[j].lat), 
                        lng: parseFloat(clusterResult[i].intermediate_addresses[j].lng)
                    }
                });
            }
        }

        setRouteAddrCoordinates(X);
    }, [clusterResult]);

    const handleFileChange = (event)=> {
      setFile(event.target.files[0])
    }

    const uploadFile = (event) => {
      const fetchAddress = ()=> {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onerror = () => {
            reject("err");
          }
          fileReader.onload = ()=> {
            if (fileReader.result !== undefined){
                const content = fileReader.result;
                let addrs = [];
                var lines = content.split('\n');
                for (var line = 0; line < lines.length; line++) {
                    if (lines[line].length < 5) continue;
                    addrs.push(lines[line])
                }
                resolve(addrs);
            } else {
                reject("Err: undefined result!");
            }
          };
          fileReader.readAsText(file);
        })
      }
      fetchAddress()
        .then((result) => {
          authAxios
          .post(unroutedAddressesUrl, {addresses: result})
          .then((response => {
            //localStorage.setItem("addresses",  JSON.stringify(response.data.good_addresses));
            if (response.data.bad_addresses.length > 0) {
                setInvalidAddresses(response.data.bad_addresses);
            }
            setRouteAddresses(response.data.good_addresses);
            let X = [{cluster: 0, addresses: [{}]}];
            for (let i=0; i<response.data.good_addresses.length; i++) {
                X[0].addresses.push({
                    id: response.data.good_addresses[i].id,
                    coordinate: {
                        lat: parseFloat(response.data.good_addresses[i].lat), 
                        lng: parseFloat(response.data.good_addresses[i].lng)
                    }
                });
            }
            setRouteAddrCoordinates(X);
          }))
          .catch(err => {
            console.log(err);
          });
        })
        .catch((err) => {
          console.log(err);
        })
    }

    const inputRef = useRef(null);
    const refClusterSize = useRef(10);
    const [addressFilter, setAddressFilter] = useState("zipcode");
    const filterChange = (e) => {
        e.preventDefault();
        setAddressFilter(e.target.value);
    }
  
    const filterAddress = (e) => {
        e.preventDefault();
        let X = [{cluster: 0, addresses: [{}]}];
        let keyword = inputRef.current.value;
        for (let i=0; i<routeAddresses.length; i++) {
            if (routeAddresses[i].addr !== null) {
                var strs = routeAddresses[i].addr.split(',');
                if ((addressFilter === "zipcode" && strs[3].search(keyword) !== -1)
                    || (addressFilter === "city" && strs[1].search(keyword.toUpperCase()) !== -1)) {
                    X[0].addresses.push({
                        id: routeAddresses[i].id,
                        coordinate: {
                            lat: parseFloat(routeAddresses[i].lat), 
                            lng: parseFloat(routeAddresses[i].lng)
                        }
                    });
                }
            }
        }
        setRouteAddrCoordinates(X);

        authAxios
        .get(getAddrCoordinateUrl(keyword))
        .then((response => {
            setCenterCoord(response.data);
        }))
        .catch(err => {
            console.log(err);
        });  
    }

    const clusterActiveAddresses =(clusterSize) => {
        authAxios
        .get(clusterAddressesUrl(clusterSize))
        .then((response => {
            setClusterResult(response.data);
        }))
        .catch(err => {
          console.log(err);
        });
    }

    const onClustering = () => {
        if (refClusterSize.current.value === "") {
            clusterActiveAddresses(10);
        } else if (refClusterSize.current.value > 25) {
            clusterActiveAddresses(25);
        } else {
            clusterActiveAddresses(refClusterSize.current.value);
        }
    }

    const [formData, setFormData] = useState({option: ''});
    const handleMarkerChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    };

    const handleMarkerSubmit = (e, marker) => {
        e.preventDefault();

        let src_route_id = clusterResult[clusterResult.findIndex(cluster=>cluster.name===marker.route)].id;
        let dst_route_id = parseInt(formData.option);
        authAxios
            .post(moveAddressBetweenRoutesUrl, 
                {
                    src_route: src_route_id,
                    target_addr: marker.address.id,
                    dst_route: dst_route_id,
                })
            .then(res=>{
                let i = clusterResult.findIndex(cluster => cluster.id === src_route_id);
                let j = clusterResult.findIndex(cluster => cluster.id === dst_route_id);
                const clusters = [...clusterResult];
                clusters[i] = res.data[0];
                clusters[j] = res.data[1];
                setClusterResult(clusters);
                setSelectedMarker(null);
            })
            .catch(err=>console.log(err));
    };

    return (
    <div class="flex flex-col-reverse lg:flex-row gap-2 mt-4 mb-8 w-full h-full">
        <div class="lg:w-1/4 w-full flex flex-col gap-2 flex-shrink-0 flex-grow-0 pr-2 border-r border-gray-200">
                <input class="default_input default_text" accept=".txt, .csv" type="file" onChange={handleFileChange} />
                <button class="default_btn" type="submit" onClick={uploadFile}>Upload</button>
                <div class="flex items-center gap-2">
                    <input type="radio" defaultChecked check={addressFilter === "zipcode"} onChange={(e)=>filterChange(e)} value="zipcode"  name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" /> 
                    <label for="default-radio-1" class="default_text">Zip Code</label>
                    <input type="radio" check={addressFilter === "city"} onChange={(e)=>filterChange(e)} value="city" name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
                    <label for="default-radio-2" class="default_text">City</label>
                </div>
                <input id="filter" name="filter" type="text" ref={inputRef} required class="default_input" />
                <button class="default_btn" type="submit" onClick={(e)=>filterAddress(e)}>Filter Address</button>
                <input id="route_capacity" type="number" placeholder='10 by default, 1~25' ref={refClusterSize}  class="default_input" />
                <button class="default_btn" type="submit" onClick={()=>onClustering()}>Clustering</button>
                {invalidAddresses.length > 0 &&  
                    <div class="flex flex-col gap-2 mt-2 border-slate-300 border-t-2 pt-2">
                        <div class="text-red-600 text-wrap text-sm">These addresses are invalid, please correct and upload them separately!</div>
                        {invalidAddresses.map((addr, index)=> (
                            <div key={index} class="text-gray-500 text-xs hover:text-blue-500 border-slate-100 border-b-2 italic">
                                {addr}
                            </div>
                        ))}
                    </div>
                }
        </div>
        <div className='w-full h-full flex-grow border-indigo-400 border'>
            <Map
                mapId='ALL_ADDRESSES_MAP_ID'
                defaultZoom={10}
                defaultCenter={ centerCoord }
                >
                {routeAddrCoordinates.length > 0 && routeAddrCoordinates.map((route, index) => {
                    return route.addresses.map(address => (
                        <AdvancedMarker position={address.coordinate} onClick={()=>setSelectedMarker({route: route.cluster, address: address})}>
                            <Pin background={adjustColor(baseMarkerColor, 
                                               Math.trunc(index/3+1)*Math.trunc(256/(Math.trunc(routeAddrCoordinates.length/3)+1))*(index % 3 === 2 ? 1 : -1),
                                                 index % 3)} 
                                glyph={route.cluster.toString()} glyphColor={'#000'} borderColor={'#000'} />
                        </AdvancedMarker>                                       
                    ))
                    }
                )}
                {selectedMarker && (
                    <InfoWindow
                        position={selectedMarker.address.coordinate}
                        onClose={()=>setSelectedMarker(null)}>
                        <form onSubmit={(e)=>handleMarkerSubmit(e, selectedMarker)} class="flex flex-col gap-2 p-2"> 
                            <label for="marker-routes" class="default_text text-nowrap">Move address to Route:</label>
                            <select class="default_select w-full" id="marker-routes" name="option" value={FormData.option} onChange={(e)=>handleMarkerChange(e)}>
                                    <option value={''}>N/A</option>
                                    {clusterResult.filter(item=>item.name !==selectedMarker.route).map(cluster => (
                                        <option value={cluster.id}>{cluster.name}</option>
                                    ))}
                                </select>
                            <button class="default_btn w-full" type="submit">Submit</button>
                        </form>
                    </InfoWindow>
                )}
            </Map>
        </div>
    </div>
  )
}

export default AddressMap;
