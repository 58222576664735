import { React, useEffect, useState } from 'react';
import Direction from '../broker/Direction';
import {Map } from '@vis.gl/react-google-maps';
import authAxios from '../../utils';
import { getActiveRoutesUrl, updateRouteStatus, updateRouteURL } from '../../constants';
import { getPackageDataUrl } from '../../constants';
import { CiDeliveryTruck } from "react-icons/ci";

const ADDR_STATUS = {
    IDLE: 0,
    DONE: 1,
    FAIL: 2
};
const ADDR_COLOR = {
    IDLE: 'bg-white',
    NEXT: 'bg-yellow-100',
    DONE: 'bg-green-100',
    FAIL: 'bg-red-100',
};

const cosineDistanceBetweenPoints = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3;
    const p1 = lat1 * Math.PI/180;
    const p2 = lat2 * Math.PI/180;
    const deltaP = p2 - p1;
    const deltaLon = lon2 - lon1;
    const deltaLambda = (deltaLon * Math.PI) / 180;
    const a = Math.sin(deltaP/2) * Math.sin(deltaP/2) +
              Math.cos(p1) * Math.cos(p2) *
              Math.sin(deltaLambda/2) * Math.sin(deltaLambda/2);
    const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)) * R;
    return d;
  }

const ActiveRoutes = () => {
    const [mapApp, setMapApp] = useState("Google");
    const [routes, setRoutes] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState(-1);
    const [displayedRoutes, setDisplayedRoutes] = useState([]);
    const [centerCoord, setCenterCoord] = useState({lat: 37.3881565, lng: -122.0027096});
    const [nextAddr, setNextAddr] = useState(0);
    const [optimizeRoute, setOptimizeRoute] = useState(false);
    const [addrBlocks, setAddrBlocks] = useState([]);
    
    useEffect(()=>{
        authAxios.get(getActiveRoutesUrl)
        .then(response=>{
            const new_routes = response.data.filter(route => route.status !== "DF");
            if (new_routes.length > 0) {
                setCenterCoord({
                    lat: new_routes[0].intermediate_addresses[0].lat, 
                    lng: new_routes[0].intermediate_addresses[0].lng })
            }
            setRoutes(new_routes);
            setDisplayedRoutes(new_routes);
        })
        .catch(err=>console.log(err));
    }, []);

    useEffect(() => {
        let i = parseInt(selectedRoute);
        setOptimizeRoute(false);

        if (i === -1) {
            setDisplayedRoutes(routes);
        } else {
            let route = routes.find(item => item.id === i);
            let X = [];
            X.push(route);

            setDisplayedRoutes(X);
            let blocks = [];
            let next = 0;
            for (let i=0; i<route.intermediate_addresses.length; i++) {
                blocks.push({
                    clicked: false,
                    status: route.intermediate_addresses[i].packages[0].status === 'DF' ? ADDR_STATUS.FAIL : (route.intermediate_addresses[i].packages[0].status === 'PD' ? ADDR_STATUS.DONE : ADDR_STATUS.IDLE),
                    order: i+1,
                    address: route.intermediate_addresses[i],
                    failure_reason: route.intermediate_addresses[i].packages[0].memo,
                });
                if (route.intermediate_addresses[i].packages[0].status === 'DF' || route.intermediate_addresses[i].packages[0].status === 'PD') {
                    next++;
                }
            }
            setAddrBlocks(blocks);
            setNextAddr(next);
        }
    }, [selectedRoute]);

    const updateRouteCallback = () => {
        if (selectedRoute === -1) return;
        authAxios.post(updateRouteStatus, {id: parseInt(selectedRoute), status: 'DF'})
        .then(res => {
            //let i = routes.findIndex(r => r.id === res.data.id);
            //let new_routes = [...routes];
            //new_routes[i] = res.data;
            const new_routes = routes.filter(route => route.id !== res.data.id);
            setOptimizeRoute(false);
            setRoutes(new_routes);
            setDisplayedRoutes(new_routes);
        })
        .catch(err=>console.log(err));
        setSelectedRoute(-1);
    }

    const drawRouteCallback = (order, route) => {
        let new_addrs = order.map(index => route.intermediate_addresses[index]);
        var i = routes.findIndex(x => x.name === route.name);
        /*console.log(nextAddr);
        console.log("previous addresses ->");
        console.log(routes[i].intermediate_addresses);
        console.log("updated addresses ->");
        */
        for (let j=nextAddr-1; j>=0; j--) {
            new_addrs.unshift(routes[i].intermediate_addresses[j]);
        }
        //console.log(new_addrs);

        let new_route = Object.assign({}, route);
        new_route.intermediate_addresses = new_addrs;

        // update the route db
        authAxios.post(updateRouteURL, {route: new_route})
        .then(response => {
            let new_routes = structuredClone(routes);
            new_routes[i] = new_route;
            setRoutes(new_routes); 
            let blocks = new_addrs.map(addr => addrBlocks.find(block => addr.id === block.address.id));
            setAddrBlocks(blocks);
        })
        .catch(err => console.log(err));  
    }

    const handleAddrBlockClick = (index) => {
        let blocks = [...addrBlocks];
        for (let i=0; i<blocks.length; i++) {
            blocks[i].clicked = i === index ;
        }
        setAddrBlocks(blocks);
  
        if (nextAddr < index) {
            // collect unvisited addresses
            let unvisited_addresses = [];
            let route = routes.find(item => item.id === parseInt(selectedRoute));
            for (let i=nextAddr; i<route.intermediate_addresses.length; i++) {
                if (index !== i) {
                    unvisited_addresses.push(route.intermediate_addresses[i]);
                } else {
                    unvisited_addresses.unshift(route.intermediate_addresses[i]);
                }
            }
            // search the farest address away from current adddress
            let d_max = 1;
            let j = -1;
            for (let i=1; i<unvisited_addresses.length; i++) {
                let d = cosineDistanceBetweenPoints(unvisited_addresses[0].lat, unvisited_addresses[0].lng, unvisited_addresses[i].lat, unvisited_addresses[1].lng);
                if (d > d_max) {
                    d_max = d;
                    j = i;
                }
            }
            if (j === -1) {
                console.log("Got error while searching for the farest address!");
                return;
            }
            setOptimizeRoute(true);

            let end = unvisited_addresses.length -1; 
            if (j !== end) {
                let tmp = unvisited_addresses[j];
                unvisited_addresses[j] = unvisited_addresses[end];
                unvisited_addresses[end] = tmp;
            }

            // rerender the remained route
            let new_route = structuredClone(displayedRoutes[0]);
            new_route.start_addr = unvisited_addresses[0].id;
            new_route.end_addr = unvisited_addresses[end].id;
            new_route.intermediate_addresses = unvisited_addresses;
            setDisplayedRoutes([new_route]);
        }
    };

    const handleFailureClick = index => {
        let blocks = [...addrBlocks];

        // update package failed to deliver 
        if (blocks[index].status === ADDR_STATUS.FAIL) {
            // navigate to this address and try to deliver again!!!
            blocks[index].status = ADDR_STATUS.IDLE;
            return;
        } 
        
        blocks[index].status = ADDR_STATUS.FAIL;
        setAddrBlocks(blocks);
    };

    const updateDeliveryFailure = (e, index) => {
        e.preventDefault();
        let blocks = [...addrBlocks];

        const data = Object.fromEntries(new FormData(e.target));
        blocks[index].failure_reason = data.reason;
        e.target.reset();

        if (index >= nextAddr) {
            setNextAddr(index+1);
        }

        authAxios.post(getPackageDataUrl+"update_status/", {address_id: blocks[index].address.id, status: 'DF', memo: data.reason})
        .then(res=>console.log(res))
        .catch(err=>console.log(err));

    };

    const handleUploadClick = index => {
        let blocks = [...addrBlocks];
        // upload photo
        blocks[index].status = ADDR_STATUS.DONE;
        setAddrBlocks(blocks);
        // 
        if (index >= nextAddr) {
            setNextAddr(index+1);
        }

        authAxios.post(getPackageDataUrl+"update_status/", {address_id: blocks[index].address.id, status: 'PD', memo:""})
        .then(res=>console.log(res))
        .catch(err=>console.log(err));
    };
    /*
    useEffect(()=>{
        if (addrBlocks.filter(block=>block.status===ADDR_STATUS.IDLE).length === 0) {
            updateRouteCallback();
        }
    },[addrBlocks]);
    */
  return (
    <div class="flex flex-col-reverse lg:flex-row gap-2 mt-4 mb-8 w-full h-full">
        <div class="w-full lg:w-1/4 flex flex-col gap-2  pr-2 border-r border-gray-200">
            <div class="flex items-center justify-center gap-2">
                <input type="radio" checked={mapApp === "Google"} onChange={()=>setMapApp('Google')} value={mapApp} name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" /> 
                <label for="default-radio-1" class="default_text">Google Map</label>
                <input type="radio" checked={mapApp === "Apple"} onChange={()=>setMapApp('Apple')} value={mapApp} name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
                <label for="default-radio-2" class="default_text">Apple Map</label>
            </div>

            <div class="flex items-center justify-start lg:justify-between gap-2 my-2">
                <label for="select-route" class="default_text text-nowrap">Select route to deliver:</label>
                <select 
                    value={selectedRoute} 
                    onChange={e => setSelectedRoute(e.target.value) } 
                    id="select-route" 
                    class="default_text w-full default_select">
                        <option value={-1}>N/A</option>
                    {routes && routes.length > 0 && routes.map((route, index) => (
                        <option key={index} value={route.id}>{route.id + ' ('+route.intermediate_addresses.reduce((a, b) => a + b.packages.length, 0) +')'}</option>
                    ))}
                </select>  
            </div>  

            {parseInt(selectedRoute) !== -1 && routes.length > 0 && routes.findIndex(r=>r.id === parseInt(selectedRoute)) !== -1 && 
                    <div class="w-full flex flex-col gap-2">
                    {addrBlocks.map((block, index) => (
                        <div key={index} 
                                class={`border-b-2 border-gray-300 p-2 shadow-md bg-clip-border rounded-md 
                                    ${nextAddr === index?ADDR_COLOR.NEXT:
                                    (block.status===ADDR_STATUS.DONE?ADDR_COLOR.DONE:(
                                        block.status===ADDR_STATUS.FAIL?ADDR_COLOR.FAIL:ADDR_COLOR.IDLE)
                                    )}`}
                                onClick={()=>handleAddrBlockClick(index)}>
                            <div class="flex gap-2 items-center default_text justify-start">
                                {nextAddr === index && <CiDeliveryTruck class="flex-grow-0 flex-shrink-0" size='24'/>}
                                <p class="default-text">{block.order}</p>
                                <p>{block.address.street+', '+block.address.city+', '+block.address.state+' '+block.address.zipcode+' ('+block.address.packages.length+')'}
                                </p>
                            </div>
                            {block.clicked && 
                            <div class="flex gap-2 px-2 pt-1 items-center default_text justify-between">
                                <button onClick={()=>handleUploadClick(index)} class="default_btn w-1/2 text-nowrap disabled:hidden" disabled={block.status ===ADDR_STATUS.DONE}>Upload Picture</button>
                                <button onClick={()=>handleFailureClick(index)} class="default_btn w-1/2 text-nowrap disabled:hidden" disabled={block.status ===ADDR_STATUS.DONE}>Fail to Deliver</button>
                            </div>
                            }
                            {block.clicked && block.status === ADDR_STATUS.FAIL && 
                            <form onSubmit={e=>updateDeliveryFailure(e, index)} class="flex flex-col gap-2 px-2 pt-2 default_text w-full">
                                <textarea class="w-full px-2 text-sm"
                                    name="reason"
                                    rows={2}
                                    defaultValue={block.failure_reason}
                                />
                                <button type="submit" class="w-full default_btn">Submit reason for failure</button>
                            </form>
                            }
                        </div>
                        ))}
                    </div>
            }
            {selectedRoute !== -1 && <button class="default_btn" type="submit" onClick={()=>updateRouteCallback()}>Close this Route</button> }
        </div>
        <div className='w-full h-full flex-grow flex-shrink-1 border-indigo-400 border'>
            <Map
                mapId='ROUTES_MAP_ID'
                defaultZoom={10}
                defaultCenter={ centerCoord }
                key = {displayedRoutes}
                >
                {displayedRoutes.length > 0 && displayedRoutes.map((route, index) => {
                    return (
                        <Direction key={index} 
                            data={route} 
                            index={index} 
                            size={displayedRoutes.length}
                            optimize={optimizeRoute}
                            callback={optimizeRoute?drawRouteCallback:null}/>                                
                    )
                })}
            </Map> 
        </div>

    </div>  )
}

export default ActiveRoutes