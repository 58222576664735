import React, { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { authLogin } from '../store/actions/auth'
import { useState } from 'react'
import { user_group } from '../constants'

const Login = () => {
  const [ loginForm, setLoginForm ] = useState({email: "", password:"", group: user_group.Broker});
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const group =  useSelector((state) => state.auth.group);
  const [ errors, setErrors ] = useState("");
  const auth_err = useSelector((state)=>state.auth.error);

  useEffect(() => {
    if (group === user_group.Driver) {
      setLoginForm(loginForm => ({
        ...loginForm,
        group: user_group.Driver,
      }));
    } else if (group === user_group.Business) {
      setLoginForm(loginForm => ({
        ...loginForm,
        group: user_group.Business,
      }));
    }      
  }, []);

  useEffect(() => {
    setErrors("");
  }, [])

  useEffect(() => {
    if (auth_err && auth_err.response && auth_err.response.data && auth_err.response.data.detail) {
        setErrors(auth_err.response.data.detail);
    }
}, [auth_err]); 

  const navigate = useNavigate();
  const handleSignupClick = () => {
    navigate("/signup");
  }
  const handleChange = e => {
    setLoginForm(loginForm => ({
        ...loginForm,
        [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = (e)=>{
      e.preventDefault();
      dispatch(authLogin(loginForm.email, loginForm.password, loginForm.group));
  };

  if (token && token !== 'null' && token !== 'undefined') {
    if (group === user_group.Driver)
      navigate("/driver-panel");
    else if (group === user_group.Business)
      navigate("/business-panel");
    else if (group === user_group.Broker)
      navigate("/broker-panel");
    else
      navigate("/");
  }


  return (
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 border-b border-gray-200">
      <Link  to="/" class="flex h-16 items-center justify-start">
        <img src="logo.png" alt="" class="w-[150px] md:w-[200px] object-cover"/>
      </Link>
      <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
        </div>
        {errors.length > 0 && (
                <div class="mt-10 flex justify-center text-red-500 text-sm">
                  *{errors}
                </div>
            )}
        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form class="space-y-6" action="#" method="POST">
            <div>
              <label for="text" class="default_text">Email</label>
              <div class="mt-2">
                <input id="email" onChange={(e)=>handleChange(e)} name="email" type="email" autocomplete="email" required class="w-full default_input" />
              </div>
            </div>

            <div>
              <div class="flex items-center justify-between">
                <label for="password" class="default_text">Password</label>
                <div class="text-sm">
                  <Link to="/forget-password" class="default_link">Forgot password?</Link>
                </div>
              </div>
              <div class="mt-2">
                <input id="password" onChange={(e)=>handleChange(e)} name="password" type="password" autocomplete="current-password" required class="w-full default_input" />
              </div>
            </div>

            <div>
              <button type="submit" onClick={(e)=>handleSubmit(e)} class=" w-full default_btn">Sign in</button>
            </div>
          </form>
          {/* 
          <p class="flex gap-1 mt-4 items-center justify-center w-full text-center text-sm text-gray-500">
            <p>No account?</p>
            <p onClick={()=>handleSignupClick()} class="ml-2 default_link">Sign Up</p>
          </p>
          */}
        </div>
      </div>
    </div>
  )
}

export default Login