import axios from "axios";
import * as actionTypes from "./actionTypes";
import {userSignupUrl, userLoginUrl} from "../../constants";

export const finishSignup = done => {
  return {
    type: actionTypes.SIGNUP_DONE,
    signup_done: done
  };
};

export const joinGroup = group => {
  localStorage.setItem("group", group);
  return {
    type: actionTypes.JOIN_GROUP,
    group: group
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = token => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("group");
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};
/*
export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};*/

export const authLogin = (email, password, group) => {
  return dispatch => {
    dispatch(authStart());
    axios
        // To use token login, rewrite TokenObtainPairView to convert email to username
        //.post("http://127.0.0.1:8000/user/token/", {
        .post(userLoginUrl, {
        email: email,
        password: password,
        group: group
      })
      .then(res => {
        const token = res.data.access;
        const refreshToken = res.data.refresh;
        //const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        //localStorage.setItem("expirationDate", expirationDate);
        dispatch(joinGroup(group));
        dispatch(authSuccess(token));
        //dispatch(checkAuthTimeout(3600));
      })
      .catch(err => {
        dispatch(authFail(err));
      });
  };
};

export const authSignup = (data) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(userSignupUrl, data)
      .then(res => {
        /*
        const token = res.data.access;
        const refreshToken = res.data.refresh;
        //const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        //localStorage.setItem("expirationDate", expirationDate);
        dispatch(authSuccess(token));
        */
        dispatch(finishSignup(true));
        //dispatch(checkAuthTimeout(3600));
      })
      .catch(err => {
        dispatch(authFail(err));
      });
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem("token");
    const group = localStorage.getItem("group");
    if (token === undefined) {
      dispatch(logout());
    } else {
        dispatch(joinGroup(group));
        dispatch(authSuccess(token));
    }
  };
}
